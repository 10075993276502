import React from 'react'

function MainPage() {
  
    return (
      <div>
            <img src="imgs/logo.svg" />
      </div>
    )
}

export default MainPage